<template>  
  <div className="fifthBlock">
    <img className="fifthBlock--back" :src="`/img/fifth/back${isMobile ? '-m' : ''}.svg`" />
    <div  className="fifthBlock__top"> </div>

    <div className="title-animation-swa fifth-swa">
      <div className="title-animation-swa__testo--text fifth-swa--text">
        <svg width="260" height="200" viewBox="0 0 260 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.96 198.424V16.876H55.35V30.16H36.244V185.14H55.35V198.424H22.96Z" />
          <path d="M128.846 165.46C122.778 165.46 117.12 164.203 111.872 161.688C106.678 159.173 102.223 155.729 98.5056 151.356C94.8429 146.928 92.2189 141.844 90.6336 136.104L104.656 132.332C105.64 136.213 107.389 139.575 109.904 142.418C112.418 145.206 115.343 147.393 118.678 148.978C122.067 150.509 125.593 151.274 129.256 151.274C134.066 151.274 138.385 150.126 142.212 147.83C146.093 145.479 149.154 142.391 151.396 138.564C153.637 134.683 154.758 130.446 154.758 125.854C154.758 121.098 153.582 116.807 151.232 112.98C148.936 109.099 145.847 106.037 141.966 103.796C138.084 101.555 133.848 100.434 129.256 100.434C124.062 100.434 119.607 101.527 115.89 103.714C112.227 105.846 109.33 108.47 107.198 111.586L94.2416 106.666L97.8496 44.92H161.236V58.778H104.902L111.38 52.71L108.346 102.156L105.148 97.974C108.428 94.4753 112.336 91.7693 116.874 89.856C121.466 87.9427 126.14 86.986 130.896 86.986C138.221 86.986 144.781 88.708 150.576 92.152C156.37 95.5413 160.962 100.188 164.352 106.092C167.741 111.941 169.436 118.529 169.436 125.854C169.436 133.125 167.577 139.767 163.86 145.78C160.142 151.739 155.195 156.522 149.018 160.13C142.895 163.683 136.171 165.46 128.846 165.46Z"/>
          <path d="M237.104 198.424H204.714V185.14H223.82V30.16H204.714V16.876H237.104V198.424Z" />
        </svg>
        <span>кое-что ещё, о чём вы не знали</span>
      </div>
    </div>      
        
        
    <div className="fifthBlock__container">
      <div className="fifthBlock__container-carousel--desk">
        <div className="fifthBlock__container-carousel-slide__item" v-for="slide in carousel" :key="'fifth-carousel-' + slide.id">
          <img className="fifthBlock__container-carousel-slide__item--img" :src="`/img/fifth/block-${slide.id}.png`" />
          <div className="fifthBlock__container-carousel-slide__item--bot">                       
            <div className="fifthBlock__container-carousel-slide__item--bot__circle"></div>
            <div className="fifthBlock__container-carousel-slide__item--bot__desc">
                {{ slide.title }}
              </div>   
          </div>            
        </div>
      </div>
      <Carousel className="fifthBlock__container-carousel--mob" v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="slide in carousel" :key="'fifth-carousel-'+slide.id">
          <div className="fifthBlock__container-carousel-slide__item">
            <img className="fifthBlock__container-carousel-slide__item--img" :src="`/img/fifth/block-${slide.id}.png`" />
            <div className="fifthBlock__container-carousel-slide__item--bot">                       
              <div className="fifthBlock__container-carousel-slide__item--bot__circle"></div>
              <div className="fifthBlock__container-carousel-slide__item--bot__desc">
                  {{ slide.title }}
                </div>     
            </div>            
          </div>
        </Slide>
      </Carousel>

      <div className="fifthBlock__container-block">
        <div className="fifthBlock__container-block--left">
          <div className="fifthBlock__container-block--left__desc">А также придумываем уникальные ингредиенты для Додо вместе с&nbsp;поставщиками — такие не найти в&nbsp;магазине</div>
          <img className="fifthBlock__container-block--left__img" src="/img/star.svg" />
        </div>
        <div className="fifthBlock__container-block--right">
            Каждый год проверяем производства наших поставщиков&nbsp;с&nbsp;помощью независимых аудиторов        
        </div>
      </div>

      <div className="fifthBlock__container-elements">
        <div className="fifthBlock__container-elements__elem" v-for="elem in array" :key="'element--' + elem.id">
          <img className="fifthBlock__container-elements__elem--img" :src="`/img/fifth/arrow${isMobile ? '-m': ''}.svg`" />
          <div className="fifthBlock__container-elements__elem--desc" v-html="elem.txt"></div>
        </div>
      </div>

      <div className="fifthBlock__container-bottom">
        <div className="fifthBlock__container-bottom-soc">
          <div className="fifthBlock__container-bottom-soc--left">
            <a className="fifthBlock__container-bottom-soc--left__link" href="#">
              <img src="/img/bk.svg" />
            </a>
            <a className="fifthBlock__container-bottom-soc--left__link" href="#">
              <img src="/img/ok.svg" />
            </a>
            <a className="fifthBlock__container-bottom-soc--left__link" href="#">
              <img src="/img/ut.svg" />
            </a>          
          </div>
          <div className="fifthBlock__container-bottom-soc--right">
            <a className="fifthBlock__container-bottom-soc--right__link" href="#">
              <img src="/img/appstore.png" />
            </a>
            <a className="fifthBlock__container-bottom-soc--right__link" href="#">
              <img src="/img/googlplay.png" />
            </a>
            <a className="fifthBlock__container-bottom-soc--right__link" href="#">
              <img src="/img/appgallery.png" />
            </a>
          </div>
        </div>

        <div className="fifthBlock__container-bottom-layer">
          <div className="fifthBlock__container-bottom-layer__left">
            <img className="fifthBlock__container-bottom-layer__logo" src="/img/logo.svg" />
            <a href="#" className="fifthBlock__container-bottom-layer__link">Официальный сайт?</a>
            <div className="fifthBlock__container-bottom-layer__desc">© 2023 ООО “Додо Франчайзинг”ОГРН 1131101001844, ИНН 1101140415 </div>
          </div>
          <div className="fifthBlock__container-bottom-layer__year">2023</div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import './fifth-style.scss';


export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      settings: {
        itemsToShow: 1.2,
        snapAlign: 'start',
      },
      breakpoints: {
        900: {
          itemsToShow: 3,
          snapAlign: 'center',
        },        
      },
      carousel: [
        {
          id: 1,
          title: 'Например, везём сыр с голубой  плесенью из Аргентины!',
        },
        {
          id: 2,
          title: 'Придумываем уникальные ингредиенты для Додо вместе с поставщиками — такие не найти в магазине',
        },
        {
          id: 3,
          title: 'Регулярно отбираем случайные образцы из поставок и проверяем их в лаборатории',
        },
      ],
      array: [
        {
          id: 1,
          txt: 'правила достойной моцареллы'
        },
        {
          id: 2,
          txt: 'как делают тесто в Додо'
        },
        {
          id: 3,
          txt: 'сколько помидоров в ложке соуса'
        },
        {
          id: 4,
          txt: 'как мы изобрели пшеницу'
        },
        {
          id: 5,
          txt: 'коровы, молоко и сыр'
        },
        {
          id: 6,
          txt: 'сколько клиентов оценили нас'
        },
        {
          id: 7,
          txt: 'откуда мы берём колбасу'
        },
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },
  },
  mounted() {

  },
  unmounted() {

  },
};
</script>
