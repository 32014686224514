<template>
  <div :class="`preloader ${isLoaded && 'loaded'}`" data-speed='1'>
    <img
      class="preloader--background"
      src="@/assets/img/background-mob.svg"
      v-if="isMobile"
    />
    <img
      class="preloader--background"
      src="/img/background.svg"
      v-else
    />
    <div class="preloader-body">
      <img class="preloader-body--logo" src="/img/logo.svg" />
      <div
        class="preloader-body--title"
        ref="title"
      >
        как мы<br v-if="isMobile" />
        делаем&nbsp;<br v-if="isMobile" />пиццу?
      </div>   
         
      <div ref="indication" class="preloader-body__indication">
        <svg
          :class="`preloader-body__indication--img ${
            updatePercent > 5 && 'start-mobile'
          }`"
          width="926"
          height="926"
          viewBox="0 0 926 926"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="isMobile"
        >
          <g ref="circle" transform-box="view-box" stroke="white" stroke-width="16" stroke-dasharray="4 90.25">
            <circle
              cx="463"
              cy="463"
              r="463"
              stroke="white"
              stroke-width="16"
              stroke-dasharray="4 88"
            />
          </g>
        </svg>

        <svg
          class="preloader-body__indication--img"
          width="926"
          height="926"
          viewBox="0 0 926 926"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          mask="url(#circleMask)"           
          v-else
        >
          <g ref="circle" transform-box="view-box" stroke="white" stroke-width="16" stroke-dasharray="4 90.25">
              <circle        
                  opacity="0.6"
                  cx="463px"
                  cy="463px"
                  r="450px"                         
                />
          </g>

          <mask id="circleMask"  maskUnits="userSpaceOnUse">
            <rect x="0" y="0" width="926" height="926" fill="white" />
            <linearGradient id="gradientFill" gradientTransform="rotate(90)">
              <stop offset="10%" stop-color="white" />
              <stop
                :offset="
                  updatePercent > 40
                    ? `${200 - 150 * (updatePercent / 100)}%`
                    : 0
                "
                :stop-color="updatePercent > 40 ? 'black' : 'white'"
              />
              <stop offset="100%" stop-color="black" />
            </linearGradient>
            <rect
              x="0"
              y="0"
              width="926"
              height="926"
              fill="url(#gradientFill)"
            />
          </mask>

          <path
            ref="path"
            d="M 10 463 a 444 444 0 1 1 906 0 a 550 500 0 0 1 -200 370"
            fill="none"
            stroke="transparent"
            vector-effect="non-scaling-stroke"
            />
            
        </svg>

        <div ref="percent" class="preloader-body__indication--percent">
          <div :class="`preloader-body__indication--percent--count ${isLoaded && 'percent--loaded'}`">
            <span ref="countingProgress"></span>&nbsp;°
          </div>
          <div
            ref="scroll"
            :class="`preloader-body--scroll ${isLoaded && 'show'}`"            
          >
            <img
              class="preloader-body--scroll-img"
              src="@/assets/img/scrollDown.svg"
            />
          </div>
        </div>
      </div>

      <div :class="`preloader-body__bot show`" ref="bot">
        <div class="preloader-body__bot--left">Длинная история коротко</div>
        <div class="preloader-body__bot--right">2011 — 2024</div>
      </div>

    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import './preloader.scss';

let ctx;
export default {
  props: ['percentLoading', 'isMobile', 'changeLoadedStatus'],
  data() {
    return {
      mainTimeLine: null,
      timer: null,
      updatePercent: 0,
      isLoaded: false,
      cacheTween: null
    };
  },
  methods: {
    goScroll() {
      document
        .querySelector('#mainContainer')
        .scrollIntoView({ behavior: 'smooth' });
    },
    setUpdatePercent() {
      const percent = this.percentLoading;
      this.updatePercent = percent;

      if (this.mainTimeLine) {
        this.cacheTween && this.cacheTween.kill();
        this.cacheTween = gsap.to(this.mainTimeLine, percent === 100 ? 1 : 5, {
          duration: 1,
          progress: (percent / 100),
        });
      }      
    },
  },
  mounted() {    
    window.scrollTo(0, 0);

    this.timer = setInterval(() => {

      window.requestAnimationFrame(this.setUpdatePercent);

      if(this.percentLoading == 100) {
        clearInterval(this.timer);
      }
    }, 100);

    ctx = gsap.context(() => {
        const tl = gsap.timeline({
          repeat: 0,
          paused: true,
          ease: 'none',
          duration: 1, 
          onComplete: () => {
            this.isLoaded = true;
            this.changeLoadedStatus(false);
          }
        });

        if (!this.$props.isMobile) {
          tl.to(
            this.$refs.percent, 1,
            {
              motionPath: {
                path: this.$refs.path,
                align: this.$refs.path,
                alignOrigin: [0.5, 0.5],                
              },
            },
            0
          )
        } 

        tl.to(this.$refs.circle, 1, {
          rotation: "232", transformOrigin: "center"
        }, 0)
        .to(this.$refs.title, 1, { scale: 1 }, 0)
        .to(this.$refs.bot, 1, {
          scale: 1, marginBottom: 0,
        }, 0)
        .to(this.$refs.countingProgress, 1, {
          innerText: 265,
          snap : {
            innerText: 1
          }
        }, 0)

        this.mainTimeLine = tl;
    });
  },
  unmounted() {
    ctx?.revert();
  },
};
</script>
