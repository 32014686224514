<template>
  <div id="mainContainer" className="title-animation">
    <div id="testMainContainer" style="position:absolute; top:0; left:0; background: #000; color: #fff; z-index:15;"></div>
    <div id="footerRef" className="title-animation--footer">     
    </div>

    <div id="testo" className="title-animation-swa">
      <div id="testotext" className="title-animation-swa__testo--text">
        <svg width="260" height="200" viewBox="0 0 260 200" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.96 198.424V16.876H55.35V30.16H36.244V185.14H55.35V198.424H22.96Z"/>
          <path d="M128.75 163V61.074L106.528 74.522V58.204L128.75 44.92H143.592V163H128.75Z"/>
          <path d="M237.104 198.424H204.714V185.14H223.82V30.16H204.714V16.876H237.104V198.424Z"/>
        </svg>
        <span>начинаем<br />с теста </span>
      </div>
      <img
        id="testoback"
        className="title-animation-swa__testo"     
        :src="`/img/testo${isMobile ? '-m' : ''}.jpg`"
      />

      <template v-html="testoback">  </template>

      <img
        id="testoup"
        className="title-animation-swa__testo--up"
        :src="`/img/dough-up${isMobile ? '-m' : ''}.jpg`"
      />
    </div>
  </div>

  <div className="content">
    <div className="content-block">
      <div className="content-block__left border">
        <div className="content-block__left-top">
          <div className="content-block__left-top--title">
            основа теста&nbsp;—&nbsp;мука
          </div>
          <div className="content-block__left-top--desc">
            Несколько лет мы искали идеальную муку. Перепробовав рецепты из
            разных типов пшеницы, мы поняли, что надо создать свой уникальный
            микс, который объединит все важные свойства
          </div>
        </div>
        <div className="content-block__left-bot">
          <img
            className="content-block__left-bot--img"
            src="@/assets/img/+.svg"
          />
          <div className="content-block__left-bot--text">
            этикетки продуктов содержат примерные значения БЖУ
            в&nbsp;соответствии со средними значениями определёнными всемирной
            организацией здоровья
          </div>
        </div>
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Смешиваем 3* типа пшеницы подбирая пропорции для каждой партии муки в&nbsp;зависимости от&nbsp;характеристик зерна
        </div>
        <img
          id="rightImg"
          className="content-block__right--img"
          :src="`/img/1${isMobile ? '-m' : ''}.png`"
          v-if="isMobile"
        />
        <img
          id="rightImg"
          className="content-block__right--img"
          src="/img/1.png"
          v-else
        />

        <div className="content-block__right--description">
          <div className="content-block__right--description__left">*</div>
          <div className="content-block__right--description__right">
            этикетки продуктов содержат примерные значения <br />
            БЖУ на 100 г. в соответствии со средними значениями
            определёнными всемирной организацией здоровья
          </div>
        </div>
      </div>
    </div>

    <div className="content-block">
      <div className="content-block__left anim-step-by-step anim-step-left">
        <div className="content-block__left-info">
          <div className="content-block__left-info--text">
            Муку измеряем на влажность, белизну, количество клейковины, удельную работу 
  и еще 7 показателей
          </div>
          <div className="content-block__left-info--imgs">
            <img src="@/assets/img/aqua.svg" />
            <img src="@/assets/img/between.svg" />
          </div>
        </div>
      </div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right-ingredients">
          <div className="content-block__right-ingredients--text first-block__right-ingredients--text">
            Проводим тест: замешиваем тесто и&nbsp;проверяем качество ингредиентов.
            Они — только натуральные
          </div>
          <img
            className="content-block__right-ingredients--items"
            src="@/assets/img/items.svg"
          />
        </div>
      </div>
    </div>

    <div className="content-block third">
      <div className="content-block__left anim-step-by-step anim-step-left">
        <img className="content-block__left--elem" src="@/assets/img/1.1.svg" />
      </div>
      <div className="content-block__right anim-step-by-step anim-step-right">
        <div className="content-block__right--title">
          Долгая медленная ферментация при низкой&nbsp;температуре делает&nbsp;корочку воздушной, а&nbsp;тесто&nbsp;—&nbsp;ароматным
        </div>
      </div>
    </div>

    <div className="content-block fourth">
      <div
        className="content-block__left fourth anim-step-by-step anim-step-left"
      >
        <div className="content-block__left-info">
          <div className="content-block__left-info--text">
            Храним тесто без заморозки сохраняя его живым
          </div>
          <div className="content-block__left-info--imgs">
            <img src="@/assets/img/gradus.svg" />
            <img src="@/assets/img/temperature.svg" />
          </div>
        </div>
      </div>

      <div className="content-block__right anim-step-by-step anim-step-right">
        <div id="twice" className="content-block__right-twice">
          <div id="twiceMore" className="content-block__right-twice--more">
            <div className="content-block__right-twice--more--hours">
              <span>48</span>
              <span>72</span>
              <img src="@/assets/img/arrow-ch.svg" />
            </div>
            <div className="content-block__right-twice--more--text">
              Разработали разные рецепты теста. Выстаиваем его в&nbsp;ресторане&nbsp;— 48&nbsp;часов, а&nbsp;при&nbsp;централизованном
              производстве&nbsp;—&nbsp;72 часа
            </div>
          </div>
          <img
            className="content-block__right-twice--img"
            src="/img/2.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './first-style.scss';

export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },
  },
  methods: {
    testoback() {
      return this.mediaArray.singleImages['testo']
    }
  },  
  mounted() {
  
  },
  unmounted() {
    
  },
};
</script>
