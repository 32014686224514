<template>
  <div id="smooth-wrapper" className="app__scroll-wrapper">
    <div id="smooth-content" className="app__scroll-content" data-scroll-container>   
      <Preloader
              :isMobile="isMobile"
              :percentLoading="percentLoading"
              :changeLoadedStatus="changeLoadedStatus"
          />   
      <MainView v-if="!isLoaded" />
        <!-- <router-view v-if="!isLoaded" /> -->
    </div>
  </div>
  
</template>

<script>
import '@/assets/scss/App.scss';
import { loadingResouces } from '@/scripts/resouces.js';
import Preloader from './views/Preloader/Preloader.vue';
import MainView from './views/MainView.vue';

export default {
  data() {
    return {
      isLoaded: true,
      percentLoading: 0,
      isMobile: false,
    };
  },
  components: { Preloader, MainView },
  methods: {
    changeContext(e) {   
      this.$store.commit('changeMediaArray', e);  
    },
    changeLoadedStatus(e) {
      this.isLoaded = e;
    },
    updatePercent(e) {
      this.percentLoading = e;
    },
    checkMobile() {
      if (window.innerWidth < 900) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.$store.commit('setIsMobile', this.isMobile);
    },
  },
  created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  mounted() {
    function isDownloadedImgs() {
      const checkWidth = window.innerWidth;

      switch (true) {
        case checkWidth > 900:
          return '1920';
        case checkWidth > 620:
          return '900';
      }
      return '400';
    }

    const correctSizeSecources = isDownloadedImgs();

    const checkWidthPage = window.innerWidth;
    let isTypeMob = false;

    if (checkWidthPage < 900) {
        isTypeMob = 'mob';
    }

    let dataImages = [
      {
        path: `/sequence/${isTypeMob ? '900' : '1920'}/tomato-up/`,
        name: 'tomato-alpha',
        extraName: `-${isTypeMob ? '900' : '1920'}_`,
        pad: 5,
        type: '.png',
        range: [0, isTypeMob ? 119 :149],
        typeMedia: 'images',
      },
      {
        path: `/sequence/${correctSizeSecources}/korobka-${correctSizeSecources}/`,
        name: 'korobka',
        extraName: `-${correctSizeSecources}_`,
        pad: 5,
        type: '.jpg',
        range: [0, 103],
        typeMedia: 'images',
      },
      {
        path: '/img/',
        name: 'testo',
        type: `${isTypeMob ? '-m' : ''}.jpg`,
        typeMedia: 'image',
      },
      {
        path: '/img/fourth/',
        name: 'test',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },    
      {
        path: '/img/tomato/',
        name: 'tomato-1',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/pizza/',
        name: 'pizza-1',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/pizza/',
        name: 'pizza-2',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/fourth/',
        name: 'block-1',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/fourth/',
        name: 'block-2',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/fourth/',
        name: 'block-3',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/fourth/',
        name: 'block-4',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/img/',
        name: 'dough-up',
        type: `${isTypeMob ? '-m' : ''}.jpg`,
        typeMedia: 'image',
      },
      {
        path: '/img/',
        name: '2',
        type: '.png',
        typeMedia: 'image',
      },
      {
        path: '/img/tomato/',
        name: 'bottle-teh',
        type: '.png',
        typeMedia: 'image',
      },
      {
        path: '/img/tomato/',
        name: 'bottle-pro',
        type: '.png',
        typeMedia: 'image',
      },
      {
        path: '/img/',
        name: '1',
        type: `${isTypeMob ? '-m' : ''}.png`,
        typeMedia: 'image',
      },
      {
        path: '/video/',
        name: 'output',
        type: `${isTypeMob ? '-m' : ''}.mp4`,
        typeMedia: 'video',
      },
      {
        path: '/video/',
        name: 'tomato-back',
        type: `${isTypeMob ? '-m' : ''}.mp4`,
        typeMedia: 'video',
      },
      {
        path: '/video/',
        name: 'cheese',
        type: `${isTypeMob ? '-m' : ''}.mp4`,
        typeMedia: 'video',
      },
    ];

    let mediaArray = [];

    dataImages.forEach((e, index) => {
      mediaArray.push({ arraySrc: [], type: e.typeMedia, name: e.name });
      if (e.typeMedia == 'video') {
        mediaArray[index].arraySrc.push(`${e.path + e.name + e.type}`);
      } else {
        if(e.range){
          for (let i = e.range[0]; i <= e.range[1]; i++) {
            mediaArray[index].arraySrc.push(
              `${e.path}${e.name}${e.extraName ? e.extraName : ''}${i.toString().padStart(5, '0')}${e.type}`
            );
          }
        } else {
          mediaArray[index].arraySrc.push(
            `${e.path}${e.name}${e.type}`
          );
        }        
      }
    });

    loadingResouces(mediaArray, this.changeContext, this.updatePercent);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
};
</script>
