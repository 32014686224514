async function preloadMediaWithRetries(
  mediaArrays,
  updatePercent,
  setMediaContextObjects
) {
  const maxRetries = 3;
  const result = {
    images: {},
    videos: {},
    singleImages: {},
  };
  let loadedMediaCount = 0;
  let loadedMediaPercent = 0;

  let progressMax = mediaArrays.reduce((sum, array) => {
    return sum + array.arraySrc.length;
  }, 0);

  function updateLoader(name) {
    const progressPercentage = Math.round(
      (loadedMediaCount / progressMax) * 100
    );
    if (loadedMediaPercent < progressPercentage) {
      loadedMediaPercent = progressPercentage;
      updatePercent(loadedMediaPercent);
    }
  }

  function preloadVideo(src, name) {
    let once = true;
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'auto';
      video.setAttribute('playsinline', '');
      video.autoplay = true;
      video.muted = true;
      video.type = 'video/mp4';
      video.customName = name;
      video.addEventListener('canplay', (e) => {
        if (once) {
          loadedMediaCount++;
          updateLoader(video.customName);
          video.autoplay = false;
          resolve(video);
          once = false;
        }
      });
      video.onerror = () => reject(`Failed to load video: ${src}`);
      video.src = src;
    });
  }

  function preloadImage(src, name) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.customName = name;
      img.onload = () => {
        loadedMediaCount++;
        updateLoader(img.customName);
        resolve(img);
      };
      img.onerror = () => reject(`Failed to load image: ${src}`);
      img.src = src;
    });
  }

  for (const media of mediaArrays) {
    for (let retry = 0; retry < maxRetries; retry++) {
      try {
        let loadedMediaItem;

        if (media.type === 'image') {
          loadedMediaItem = await preloadImage(media.arraySrc[0], media.name);
        } else if (media.type === 'video') {
          loadedMediaItem = await preloadVideo(media.arraySrc[0], media.name);
        } else {
          loadedMediaItem = [];
          for (let img of media.arraySrc) {
            const loadedImg = await preloadImage(img);
            loadedMediaItem.push(loadedImg);
          }
        }

        if (media.type === 'video') {
          result.videos = { ...result.videos, [media.name]: loadedMediaItem };
        } else if (media.type === 'image') {
          result.singleImages = {
            ...result.singleImages,
            [media.name]: loadedMediaItem,
          };
        } else {
          result.images = { ...result.images, [media.name]: loadedMediaItem };
        }

        break; // Break out of the retry loop if successful.
      } catch (error) {
        console.error(error);
      }
    }
  }

  setMediaContextObjects(result);
  if (loadedMediaPercent !== 100) updatePercent(100);
}

function loadingResouces(mediaArrays, setMediaContextObjects, updatePercent) {
  preloadMediaWithRetries(mediaArrays, updatePercent, setMediaContextObjects);
}

export { loadingResouces };
