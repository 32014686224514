<template>      
      <my-custom-first-component key="first--component" />
      <my-custom-second-component key="second--component" />
      <my-custom-third-component key="third--component" />
      <my-custom-fourth-component key="fourth--component" />
      <my-custom-fifth-component key="fifth--component" />

      <!-- <PizzaComponent /> -->
      <!-- <ProviderComponent /> -->

</template>

<script>

// import Konva from 'konva'
// import { fabric } from 'fabric';
import * as PIXI from 'pixi.js';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { ScrollSmoother } from '@/scripts/ScrollSmoother';
// import LocomotiveScroll from 'locomotive-scroll'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);



// import PizzaComponent from '@/components/Pizza/Pizza.vue';
// import ProviderComponent from '@/components/Provider/Provider.vue';
import FirstComponent from '@/components/First/First.vue';
import SecondComponent from '@/components/Second/Second.vue';
import ThirdComponent from '@/components/Third/Third.vue';
import FourthComponent from '@/components/Fourth/Fourth.vue';
import FifthComponent from '@/components/Fifth/Fifth.vue';

let ctx;

export default {
  name: 'MainView',
  components: {
    // PizzaComponent,
    // ProviderComponent,
    'my-custom-first-component' : FirstComponent,
    'my-custom-second-component': SecondComponent,
    'my-custom-third-component': ThirdComponent,
    'my-custom-fourth-component': FourthComponent,
    'my-custom-fifth-component': FifthComponent
  },
  data() {
    return {
  
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    mediaArray() {
      return this.$store.state.mediaArray;
    },   
  },
  mounted (){

    // if (this.isMobile) {
    //   ScrollTrigger.normalizeScroll(true);

    //   ScrollSmoother.create( {
    //     wrapper: '#smooth-wrapper',
    //     content: '#smooth-content',
    //     smooth: 0.5,
    //     smoothTouch: this.isMobile ? 0 : 1,
    //     normalizeScroll: true,
    //     effects: true,
    //     ignoreMobileResize: false,
    //     preventDefault: true
    //   })
    // }

    //   ScrollTrigger.defaults({
    //     scroller: document.getElementById('smooth-content'),
    //     markers: false
    //   });

    //   var scroll = new LocomotiveScroll({
    //     el: document.getElementById('smooth-content'),
    //     smooth: true
    //   });

    //   // Update scroll position
    //   scroll.on('scroll', (instance) => {
    //     ScrollTrigger.update();
    //     document.documentElement.setAttribute('data-scrolling', instance.direction);
    //   });

    //   ScrollTrigger.scrollerProxy(document.getElementById('smooth-content'), {
    //     scrollTop(value) {
    //       return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y;
    //     },
    //     getBoundingClientRect() {
    //       return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
    //     },
    //     pinType: document.getElementById('smooth-content')?.style.transform ? "transform" : "fixed"
    //   });

    //   ScrollTrigger.addEventListener('refresh', () => scroll.update());
    //   ScrollTrigger.refresh();
    // }


    let stage;
    let canvasFirstVideo;
    let chacheTexture;
    let canvasFirstTransition;
    let scene;
    let camera;
    // Konva.pixelRatio = 1;

    const boxFirstVideo = this.isMobile ? this.mediaArray.images['korobka'] : this.mediaArray.videos['output'] ;
    // const boxFirstVideo = this.mediaArray.videos['output'];
    const tomatoBackVideo = this.mediaArray.videos['tomato-back'];
    const canvasBackVideo = document.createElement('canvas');
    const cheeseVideo = this.mediaArray.videos['cheese'];
    const tomatoUpSequence = this.mediaArray.images['tomato-alpha'];
    const fourthImage = document.getElementById('fourthImg');

    const tomatoContainerFixed = document.getElementById('tomatoContainerFixed');
    const fourthContainer = document.getElementById('fourthContainer');
    const fourthCarouselContainer = document.getElementById('fourthCarouselContainer');
    const tomatoUpSequenceCanvas = document.createElement('canvas');

    tomatoBackVideo.classList.add('title-animation--footer-video');

    if(this.isMobile) {
    //   // stage = new Konva.Stage({
    //   //   container: 'footerRef',
    //   //   width: window.innerWidth,
    //   //   height: window.innerHeight
    //   // });
      canvasFirstVideo = new PIXI.Application({ width: boxFirstVideo[0].width, height: boxFirstVideo[0].height });
      // canvasFirstVideo.classList.add('title-animation--footer-video');
      // canvasFirstVideo.width = window.innerWidth;
      // canvasFirstVideo.height = window.innerHeight;
    //   canvasFirstVideo.customContentWidth = boxFirstVideo[0].width;
    //   canvasFirstVideo.customContentHeight = boxFirstVideo[0].height;
    //   // canvasBackVideo.classList.add('title-animation--footer-video');
    //   // boxFirstVideo.classList.add('title-animation--footer-video__hidden');

    //   // canvasFirstVideo = new Konva.Layer();
    //   // canvasFirstVideo.listening(false);
    //   // stage.add(canvasFirstVideo);

    //   // canvasFirstVideo = new fabric.StaticCanvas('footer-first', {
    //   //   isDrawingMode: false,
    //   //   height: window.innerHeight,
    //   //   width: window.innerWidth
    //   // })

    } else {      
      boxFirstVideo.classList.add('title-animation--footer-video');
    }


    cheeseVideo.classList.add('title-animation--footer-video');
    tomatoUpSequenceCanvas.classList.add('title-animation--footer-video');
    
    
    tomatoUpSequenceCanvas.width = tomatoUpSequence[0].width;
    tomatoUpSequenceCanvas.height = tomatoUpSequence[0].height;
    
    if(this.isMobile) {

      document.getElementById('footerRef').appendChild(canvasFirstVideo.view);

      chacheTexture = [];
     
      boxFirstVideo.forEach((img, index) =>{
        const base = new PIXI.BaseTexture(img);
        chacheTexture.push( new PIXI.Texture(base))
      })

      canvasFirstTransition = new PIXI.Sprite(chacheTexture[0]);

      canvasFirstVideo.stage.addChild(canvasFirstTransition);

      // document.getElementById('footerRef').appendChild(boxFirstVideo);
    } else {
      document.getElementById('footerRef').appendChild(boxFirstVideo);
    }

    

    // if(this.isMobile) {
    //   canvasBackVideo.width = tomatoBackVideo.width;
    //   canvasBackVideo.height = tomatoBackVideo.height;
    //   document.getElementById('tomatoAnimation').appendChild(canvasBackVideo);
    // } else {
    //   tomatoBackVideo.width = tomatoBackVideo.videoWidth;
    //   tomatoBackVideo.height = tomatoBackVideo.videoHeight;
    //   tomatoBackVideo.poster = '/sequence/1920/tomato-up/tomato-alpha-1920_00000.png';
    //   document.getElementById('tomatoAnimation').appendChild(tomatoBackVideo);
    // }
    
    // document.getElementById('cheeseVideoId').appendChild(cheeseVideo)
    // tomatoContainerFixed.appendChild(tomatoUpSequenceCanvas);

    
    const mainContainer = document.getElementById('mainContainer');
    // const tomatoContainer = document.getElementById('tomatoContainer');
    // const cheeseVideoContainer = document.getElementById('cheeseVideoContainer');
    const fourthBlock = document.getElementById('fourthBlock');
    const testo = document.getElementById('testo');
    const testotext = document.getElementById('testotext');
    // const tomatoSwaText = document.getElementById('tomatoSwaText');
    // const cheeseSwaText = document.getElementById('cheeseSwaText');
    const testoback = document.getElementById('testoback');
    const testoup = document.getElementById('testoup');
    const twice = document.getElementById('twice');
    const twiceMore = document.getElementById('twiceMore');

    ctx = gsap.context((self) => {



      const boxFrames = {
        frame: -1,
        currentFrame: -1
      };

      
      if(this.isMobile) {
        // chacheKonvaImage = boxFirstVideo.map((img, index) => {
        //   const cache = new fabric.Image({
        //     image: img,
        //     x: 0,
        //     y: 0,
        //     height: img.height,
        //     width: img.width
        //   });

        //   // canvasFirstVideo.add(cache);
        //   // canvasFirstVideo.renderAll();

        //   return cache;

        //   // const konvaImage = new Konva.Image({
        //   //   image: img,
        //   //   width: stage.width(),
        //   //   height: stage.height(),
        //   // });

        //   // canvasFirstVideo.removeChildren();
        //   // canvasFirstVideo.add(konvaImage);
        //   // canvasFirstVideo.draw();
        //   // konvaImage.cache();
        //   // return konvaImage
        // })
        
        
      }

      // if(this.isMobile) {
      //   canvasFirstTransition = canvasFirstVideo.getContext('2d');
      //   canvasFirstTransition.drawImage(boxFirstVideo[0], 0, 0, canvasFirstVideo.customContentWidth, canvasFirstVideo.customContentHeight, 0, 0, canvasFirstVideo.width, canvasFirstVideo.height);

      // }
      
      const renderCanvasBox = () => {        
        document.getElementById('testMainContainer').innerText = boxFrames.frame
          // console.log(boxFrames.frame, boxFrames.currentFrame, boxFirstVideo.duration * 30)

          if (this.isMobile && boxFirstVideo[boxFrames.frame]) {
            console.log(boxFrames.frame, boxFrames.currentFrame)
            canvasFirstTransition.texture = chacheTexture[boxFrames.frame]
            
          } else if (0 <= boxFrames.frame < boxFirstVideo.duration * 30) {
            boxFirstVideo.currentTime = boxFrames.frame / 30
          }
          

          // canvasFirstVideo.remove(chacheKonvaImage[boxFrames.currentFrame]);
          // canvasFirstVideo.add(chacheKonvaImage[boxFrames.frame]);
          // canvasFirstVideo.renderAll()

          
          // console.log(chacheKonvaImage[boxFrames.frame]);
          // canvasFirstVideo.renderAll();
          // const konvaImage = new Konva.Image({
          //   image: boxFirstVideo[boxFrames.frame],
          //   width: stage.width(),
          //   height: stage.height(),
          // });

          // canvasFirstVideo.removeChildren();
          // canvasFirstVideo.add(konvaImage);
          // canvasFirstVideo.draw();
          // canvasFirstVideo.cache();
          boxFrames.currentFrame = boxFrames.frame
      }


      //FIRST COMPONENT
      const timeMainContainer = gsap
        .timeline({          
          scrollTrigger: {
            id: 'mainContainer',
            trigger: mainContainer,
            start: 'top top',
            scrub: true,            
            pin: true,      
            end: '+=400%',
            scroller: this.isMobile ? null : '#smooth-content',
            markers: true,
            invalidateOnRefresh: true,
          },
        })

        timeMainContainer.to(boxFrames, {           
          frame: this.isMobile ? boxFirstVideo.length - 1 : Math.round(boxFirstVideo.duration * 30),
          snap: 'frame',           
          ease: 'none', 
          onUpdate: () => boxFrames.currentFrame !== boxFrames.frame && window.requestAnimationFrame(renderCanvasBox), 
          duration: 10 })
       
        
        timeMainContainer.set(testo, { opacity: 1 })
        .set(testotext, { opacity: 0 })
        .fromTo(
          testoup,
          { clipPath: 'inset(0% 0% 0% 0%)' },
          { clipPath: 'inset(0 0 100% 0)', duration: 5 },
          10
        )
        .to(testotext, { opacity: 1, duration: 3, delay: 2 }, 10)
        .to(testoback, { scale: 1.1, duration: 5, delay: 3 }, 10)
        .set(boxFirstVideo, {opacity: 0}, 20)
        .set(testoup, {opacity: 0}, 20)
        .to(testoback, { opacity: 0, duration: 6 }, 20)
        .to(testotext, { color: '#000', fill: '#000', duration: 3, delay: 3 }, 20)

        // if(!this.isMobile) timeMainContainer.to(testotext, { top: '40%', duration: 4 }, 26);
        

      //   // SECOND COMPONENT
      //   const timeTomatoContainer = gsap.timeline({
      //     scrollTrigger: {
      //       id: 'tomatoBackVideoContainer',
      //       trigger: tomatoContainer,
      //       start: 'top top',
      //       scrub: true,
      //       pin: true,
      //       end: '+=250%',
      //       markers: true,
      //     },
      //   })
      //   .to(tomatoBackVideo, { currentTime: tomatoBackVideo.duration, duration: 14 }, 0)
      //   .to(tomatoSwaText, { color: '#fff', fill: '#fff', duration: 10, delay: 4 }, 0)
      //   .to(tomatoBackVideo, { opacity: 0, duration: 4 }, 20)
      //   .to(tomatoSwaText, { color: '#000', fill: '#000', duration: 4 }, 20)
      //   // if (!this.isMobile) timeTomatoContainer.to(tomatoSwaText, { top: '50%', duration: 6 }, 26);

      //   cheeseVideo.style.opacity = 0;
      //   const timeCheeseContainer = gsap.timeline({
      //   scrollTrigger: {
      //     id: 'cheeseVideoContainer',
      //     trigger: cheeseVideoContainer,
      //     start: 'top top',
      //     scrub: true,
      //     pin: true,
      //     end: '+=300%',
      //     markers: true,
      //   },
      // })
      //   .to(cheeseVideo, { currentTime: cheeseVideo.duration, duration: 14 }, 0)   
      //   .to(cheeseVideo, { opacity: 1, duration: 2 }, 0)    
      //   .to(cheeseSwaText, { color: '#fff', fill: '#fff', duration: 4, delay: 2 }, 0)
      //   .to(cheeseVideo, { opacity: 0, duration: 2}, 20)
      //   .to(cheeseSwaText, { color: '#000', fill: '#000', duration: 2, delay: 2 }, 20)
      //   // if(!this.isMobile) timeCheeseContainer.to(cheeseSwaText, { top: '50%', duration: 6 }, 26);   
        
        
        


      //   // gsap.timeline({
      //   //   scrollTrigger: {
      //   //     id: 'tomatoContainerFixed',
      //   //     trigger: tomatoContainer,
      //   //     start: '-50% top',
      //   //     scrub: true,
      //   //     end: '+=150%',
      //   //     // markers: true,
      //   //   },
      //   // })
      //   // .set(tomatoContainerFixed, { position: 'fixed', top: 0, left: 0 }, 0)
      //   // .to(tomatoUpVideo, { currentTime: tomatoUpVideo.duration, duration: 20 }, 0)
      //   // .set(tomatoContainerFixed, { position: 'absolute', top: 0, left: 0, duration: 15 }, 5)

      //   let frames = {
      //     frame: 0
      //   };

      //   const canvasTransition = tomatoUpSequenceCanvas.getContext('2d');

      //   const renderCanvasTomato = () => {
      //     console.log('dfdsfds')
      //     if (tomatoUpSequence[frames.frame]) {
      //       canvasTransition.clearRect(
      //         0,
      //         0,
      //         canvasTransition.canvas.width,
      //         canvasTransition.canvas.height
      //       );
      //       canvasTransition.drawImage(tomatoUpSequence[frames.frame], 0, 0);
      //     }
      //   }

      //   gsap.to(frames,{
      //     frame: tomatoUpSequence.length,
      //     snap: 'frame',
      //     onUpdate: renderCanvasTomato,
      //     scrollTrigger: {
      //       id: 'tomatoContainerFixed',
      //       trigger: tomatoContainerFixed,
      //       start: 'top top',
      //       scrub: true,
      //       invalidateOnRefresh: true,
      //       end: '+=60%',
      //        markers: true,
      //     },
      //   })
        

      

      // const allLeftBlocks = gsap.utils.toArray('.anim-step-left');
      // const allRightBlocks = gsap.utils.toArray('.anim-step-right');

      // allLeftBlocks.forEach((element, i) => {
      //   gsap
      //     .timeline({
      //       scrollTrigger: {
      //         id: 'leftBlock' + i,
      //         trigger: element,
      //         start: `top 75%`,
      //         scrub: true,
      //         end: '+=25%',
      //         markers: true,
      //         once: true,
      //       },
      //     })
      //     .set(element, { opacity: 1 });
      // });

      // allRightBlocks.forEach((element, i) => {
      //   gsap
      //     .timeline({
      //       scrollTrigger: {
      //         id: 'rightBlock' + i,
      //         trigger: element,
      //         start: `top 45%`,
      //         scrub: true,
      //         end: '+=25%',
      //          markers: true,
      //         once: true,
      //       },
      //     })
      //     .to(element, { opacity: 1 });
      // });

      // gsap.utils.toArray('#rightImg').forEach((img, i) => {
      //   gsap
      //     .timeline({
      //       scrollTrigger: {
      //         id: `imgRight-${i}`,
      //         trigger: img,
      //         start: 'top 60%',
      //         scrub: true,
      //         end: '+=25%',
      //          markers: true,
      //       },
      //     })
      //     .fromTo(img, { yPercent: 15 }, { yPercent: 0 });
      // })
      

      // if(!this.isMobile) {
      //   gsap.timeline({
      //     scrollTrigger: {
      //       id: 'twiceMore',
      //       trigger: twiceMore,
      //       start: 'top 50%',
      //       pin: true,
      //       pinSpacing: false,
      //       endTrigger: twice,
      //       end: () => '+=' + (twice.offsetHeight - twiceMore.offsetHeight) + ' 50%',
      //       markers: true,
      //     },
      //   });
      // }
      

      // // Fourth component
      // const scrollTL = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: fourthBlock,
      //     pin: true,
      //     start: "top top",
      //     scrub: 1,
      //     invalidateOnRefresh: true,
      //     end: `+=800%`
      //   }
      // });

      // const fourtPositionImg = document.getElementById('fourthPositionImg');


      // scrollTL.to(fourthImage, { marginTop: '-15%', duration: 5 })
      // scrollTL.set(fourthContainer, { display: 'none' }, 4)
      // scrollTL.to(fourthContainer, { marginTop: '-90%', duration: 1 }, 4)
      // scrollTL.to(fourthCarouselContainer, { opacity: 1, duration: 10 }, 5)
      // if (this.isMobile) {
      //   scrollTL.to(fourthImage, { width: '51%', marginTop: `${window.innerHeight * 0.3795}px`, marginLeft: `${window.innerWidth * 0.1425}px`, duration: 10 }, 5)
      // } else {
      //   scrollTL.to(fourthImage, { width: '35.5%', marginTop: `${fourthBlock.offsetHeight * 0.152}px`, marginLeft: `${fourtPositionImg.offsetLeft * 0.962}px`, duration: 10 }, 5)
      // }
      // scrollTL.to(fourthImage, { opacity: 0, duration: 0 })

      // let sections = gsap.utils.toArray("#fourthCarouselSections");

      // // scrollTL.to(fourthContainer, {position:'relative'})

      // sections.forEach((section, index) => {
      //   scrollTL.set(section, { opacity: 1 })
      //     .to(section, {
      //       opacity: 1, // <-- IMPORTANT!              
      //       duration: 5
      //     },
      //       "+=10")
      //     .set(section, { opacity: 0 });
      // })


  

      // end CTX
    });





    const isTouchDevice = () => {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };
    if (isTouchDevice()) {
      //boxFirstVideo?.play();
      //boxFirstVideo?.pause();
    }
  },
  beforeDestroy(){
    ctx.revert();
  }
};
</script>
